import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { DARK_BLUE } from '../styles/colors.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5%',
    height: '100%',
  },
  card: {
    borderRadius: '1.8vh',
    boxShadow: '0px 0px 20px 15px rgba(0, 0, 0, 0.43)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '5%',
    [theme.breakpoints.down('lg')]: {
      boxShadow: '0px 0px 12px 6px rgba(0, 0, 0, 0.43)',
    },
  },
  noShadow: {
    boxShadow: 'none !important',
  },
  container: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));

const Card = ({ children, className, color, hasShadow, wrapperClassName }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, wrapperClassName)}>
      <div
        className={clsx(className, classes.card, !hasShadow && classes.noShadow)}
        style={{
          backgroundColor: color ? color : DARK_BLUE,
        }}
      >
        <div className={classes.container}>{children}</div>
      </div>
    </div>
  );
};

export default Card;
