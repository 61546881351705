import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/styles.css';
import test from '../styles/carousel.scss';

import clsx from 'clsx';
import Card from './Card';
import Loading from './Loading';
import NoContent from './NoContent';
import { INTERVAL_SLIDE_CHANGE } from 'constants/config';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  fakeCardForShadow: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -1,
  },
}));

const Carousel = ({ children, className, isLoading, noContent, intervalSlideChange }) => {
  const classes = useStyles();

  const [cardIndex, setCardIndex] = useState(0);

  useEffect(() => {
    if (children.length > 1) {
      const interval = setInterval(
        () => {
          setCardIndex((cardIndex) => (cardIndex === children.length - 1 ? 0 : cardIndex + 1));
        },
        intervalSlideChange ? intervalSlideChange : INTERVAL_SLIDE_CHANGE,
      );
      return () => clearInterval(interval);
    }
  }, [children]);

  return (
    <>
      <AwesomeSlider
        animation="scaleOutAnimation"
        cssModule={[CoreStyles, test]}
        organicArrows={false}
        className={clsx(classes.root, className)}
        mobileTouch={false}
        selected={cardIndex}
      >
        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : noContent ? (
          <div>
            <NoContent />
          </div>
        ) : (
          children
        )}
      </AwesomeSlider>
      <Card wrapperClassName={classes.fakeCardForShadow} hasShadow={true}></Card>
    </>
  );
};

export default Carousel;
