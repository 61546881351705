import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';

import { useHistory } from 'react-router-dom';
import { useStore } from 'store/storeUtils';
import getParamByName from 'utils/getParamByName';

let socket;
const roomId = getParamByName('room');
const isController = getParamByName('isController') === 'true';

const Client = (props) => {
  const history = useHistory();
  const store = useStore();

  const [isSubscribed, setIsSubscribed] = useState(false);

  // areYouController(store);

  useEffect(() => {
    if (roomId) {
      socket = socketIOClient.connect('wss://tecma-websocket.herokuapp.com/');

      socket.on('connect', async () => {
        console.log('connect');
        socket.emit('subscribe', roomId);
      });

      socket.on('subscribed', async () => {
        console.log('subscribed');
        setIsSubscribed((isSubscribed) => !isSubscribed);
      });

      socket.on('click-event', (event) => {
        const type = event.type;
        if (type === 'change-page') {
          if (!isController) {
            history.push({ pathname: event.page });
          }
        } else if (type === 'change-name') {
          store.setClientName(event.name);
        }
      });
    }
  }, []);

  return null;
};

export const onMenuClick = (page) => {
  if (isController) {
    socket.emit('click-event', {
      roomId,
      event: {
        type: 'change-page',
        page: page,
      },
    });
  }
};

export const changeClientName = (name) => {
  if (isController) {
    socket.emit('click-event', {
      roomId,
      event: {
        type: 'change-name',
        name: name,
      },
    });
  }
};

export default Client;
