import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

import logo from '../assets/images/redilco_logo.svg';
import moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#fff',
    height: '100%',
  },
  logo: {
    height: '1.5vh',
    width: 'auto',
    marginTop:'-30px'
  },
  dateTime: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    fontWeight: 600,
    fontSize: '2vh',
  },
  time: {
    fontWeight: 200,
    fontSize: '2vh',
  },
}));

const Header = ({}) => {
  const classes = useStyles();

  const calculateDate = () => {
    return moment();
  };

  const [date, setDate] = useState(calculateDate());

  useEffect(() => {
    setTimeout(() => {
      setDate(calculateDate());
    }, 1000);
  });

  return (
    <Grid container className={classes.container}>
      <Grid item xs>
        <Link to="/menu">
          <Box display="flex" alignItems="center" height="100%">
            <img src={logo} className={classes.logo} />
          </Box>
        </Link>
      </Grid>
      <Grid item className={classes.dateTime}>
        <div>
          <Box fontWeight={600}>
            <Typography className={classes.date}>
              {date.format("MMMM Do YYYY")}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.time}>
              {date.format("H:mm")}{" "}
            </Typography>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Header;
