import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Carousel from 'components/Carousel';
import { UPDATE_INTERVAL } from 'constants/config';
import NewsCard from 'components/NewsCard';
import { toJS } from 'mobx';

let firstLoad = true;

const News = observer(() => {
  const store = useStore();

  const { newsData, isLoading } = store;

  useEffect(() => {
    if (newsData.data.length === 0 && firstLoad) {
      store.getNewsDataAll();
    }

    if (newsData.data.length > 0) {
      firstLoad = false;
    }

    const interval = setInterval(() => {
      store.getNewsDataAll();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <Carousel isLoading={isLoading && firstLoad} noContent={newsData.noContent}>
      {newsData.data.length > 0 &&
        newsData.data.map((news, i) => {
          return (
            <div key={i}>
              <NewsCard news={news} />
            </div>
          );
        })}
    </Carousel>
  );
});

export default News;
