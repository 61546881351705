import axios from 'axios';

import {
  weatherParams,
  forecastHours,
  newsTopics,
  departureAirports,
  arrivalAirports,
  markets,
} from './ApiConstants';

import moment from 'moment';

class Api {
  constructor() {
    this.getMarketChartData = this.getMarketChartData.bind(this);
    this.getMarketSummaryData = this.getMarketSummaryData.bind(this);
    this.filterNewsBySources = this.filterNewsBySources.bind(this);
  }

  // GET STOCK INFO PAGE X PAGE
  async getStockMarketData() {
    console.log('### Getting Stock Market Data..');

    const response = {
      noContent: false,
      data: [],
    };

    try {
      for (const el of markets) {
        // const chart = await this.getMarketChartData(el.region, el.symbol);
        // const info = await this.getMarketSummaryData(el.region);

        const [chart, info] = await Promise.all([
          this.getMarketChartData(el.region, el.symbol),
          this.getMarketSummaryData(el.region),
        ]);

        if (chart.chart.result.length > 0 && info.marketSummaryResponse.result.length > 0) {
          response.data.push({
            ...el,
            chart: chart.chart.result[0],
            info: info.marketSummaryResponse.result,
          });
        }
      }
    } catch (error) {
      response.noContent = true;
    }

    return response;
  }

  // GET ALL STOCK INFO AT THE SAME TIME
  // getStockMarketPageData = async (region, symbol) => {
  //   console.log('### Getting Stock Market Data..');

  //   const [chart, info] = await Promise.all([
  //     this.getMarketChartData(region, symbol),
  //     this.getMarketSummaryData(region),
  //   ]);

  //   const response = {
  //     chart: chart.chart.result[0],
  //     info: info.marketSummaryResponse.result,
  //   };

  //   return response;
  // };

  // getStockMarketData = async () => {
  //   const callToDo = [];
  //   for (const el of markets) {
  //     callToDo.push(this.getStockMarketPageData(el.region, el.symbol));
  //   }

  //   const response = await Promise.all(callToDo);
  //   console.log(response);

  //   return response;
  // };

  async getMarketChartData(region, marketSymbol) {
    const res = await axios({
      method: 'GET',
      url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/market/get-charts',
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        region: region,
        lang: 'en',
        symbol: marketSymbol,
        interval: '5m',
        range: '1d',
      },
    });

    return res.data;
  }

  async getMarketSummaryData(region) {
    const res = await axios({
      method: 'GET',
      url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/market/get-summary',
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        region: region,
        lang: 'it',
      },
    });

    return res.data;
  }

  async getWeatherData() {
    console.log('### Getting Weather Data..');

    const response = {
      noContent: false,
      data: [],
    };
    try {
      for (const el of weatherParams) {
        const cityWeather = await this.getCityWeatherData(el.lat, el.long);
        response.data.push(cityWeather);
      }
    } catch (error) {
      response.noContent = true;
    }

    return response;
  }

  async getCityWeatherData(lat, long) {
    const data = {};

    const res = await axios({
      method: 'GET',
      url: 'https://weatherbit-v1-mashape.p.rapidapi.com/current',
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'weatherbit-v1-mashape.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        lang: 'en',
        lat: lat,
        lon: long,
      },
    });

    data.current = res.data;

    const res2 = await axios({
      method: 'GET',
      url: 'https://weatherbit-v1-mashape.p.rapidapi.com/forecast/hourly',
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'weatherbit-v1-mashape.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        lang: 'en',
        hours: forecastHours,
        lat: lat,
        lon: long,
      },
    });

    data.forecast = res2.data;

    return data;
  }

  async getNewsData() {
    console.log('### Getting News Data..');

    const response = {
      noContent: false,
      data: [],
    };

    try {
      for (const el of newsTopics) {
        const news = await this.getNewsByTopicNew(el.topic);

        const filteredNews = this.filterNewsBySources(news, el.sourceToUse, el.otherSources);

        if (filteredNews.length > 0) {
          response.data.push(filteredNews[0]);
        }
      }
    } catch (error) {
      response.noContent = response.data.length === 0 ? true : false;
    }

    return response;
  }

  filterNewsBySources(news, source, otherSources) {
    let filteredNews = news.filter((el) => el.source_name === source);

    if (filteredNews.length === 0 && otherSources.length > 0) {
      filteredNews = news.filter((el) => otherSources.includes(el.source_name));
    }

    return filteredNews;
  }

  async getFlightData() {
    console.log('### Getting Flight Data..');

    const response = {
      noContent: false,
      data: [],
    };

    const fromDate = moment().format('YYYY-MM-DDTHH:mm');
    const toDate = moment().add(11, 'h').format('YYYY-MM-DDTHH:mm');

    try {
      for (const dep of departureAirports) {
        const temp = { airport: dep, departures: [] };
        const flight = await this.getFlightByFilter(dep.icao, fromDate, toDate);
        temp.departures = flight.departures.filter((el) =>
          arrivalAirports.includes(el.movement.airport.icao),
        );

        if (temp.departures.length < 5) {
          const otherAirports = flight.departures.filter(
            (el) => !arrivalAirports.includes(el.movement.airport.icao),
          );

          if (otherAirports.length > 0) {
            let i = 0;
            let length = temp.departures.length;
            while (length < 5) {
              if (otherAirports[i]) {
                temp.departures.push(otherAirports[i++]);
              }

              length++;
            }
          }
        }

        temp.departures = temp.departures.sort((a, b) => {
          if (
            moment(a.movement.scheduledTimeLocal, 'YYYY-MM-DD HH:mmZ').isAfter(
              moment(b.movement.scheduledTimeLocal, 'YYYY-MM-DD HH:mmZ'),
            )
          ) {
            return 1;
          } else {
            return -1;
          }
        });

        if (temp.departures.length > 0) {
          response.data.push(temp);
        }
      }
    } catch (error) {
      response.noContent = true;
    }

    return response;
  }

  async getFlightByFilter(departure, fromDate, toDate) {
    const res = await axios({
      method: 'GET',
      url: `https://aerodatabox.p.rapidapi.com/flights/airports/icao/${departure}/${fromDate}/${toDate}`,
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'aerodatabox.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        withLeg: 'false',
        withCancelled: 'false',
        withCodeshared: 'false',
        withCargo: 'false',
        withPrivate: 'false',
        direction: 'Departure',
      },
    });

    return res.data;
  }

  async getNewsByTopicData(topic) {
    const res = await axios({
      method: 'GET',
      url: 'https://newscatcher.p.rapidapi.com/v1/latest_headlines',
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'newscatcher.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        topic: topic,
        lang: 'en',
        country: 'US',
        media: 'True',
      },
    });

    return res.data;
  }
  async getNewsByTopicNew(topic) {
    const res = await axios({
      method: 'GET',
      url: `https://newscafapi.p.rapidapi.com/apirapid/news/${topic}/`,
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'newscafapi.p.rapidapi.com',
        'x-rapidapi-key': '4778fee33dmsh061e68fccd548d8p12cbafjsnc8e211e47a8e',
        useQueryString: true,
      },
      params: {
        q: 'news',
      },
    });

    return res.data;
  }
}

export const ApiService = new Api();
