import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from './Card';
import moment from 'moment';
import FlightIcon from '@material-ui/icons/Flight';
import FlightPicture from 'assets/images/flight.png';

import { LIGHT_GREY, GREY_BLUE } from '../styles/colors.scss';

import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '2vh 2.5vw',
    overflow: 'hidden',
    color: GREY_BLUE,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  title: {
    fontSize: '2.4vh',
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: '1.6vh',
  },
  name: {
    fontSize: '0.9vw',
    textTransform: 'uppercase',
  },
  departureRoot: {
    position: 'relative',
    marginBottom: '1vh',
  },
  departureContainer: {
    padding: '1.3vh 4.9vw',
    backgroundColor: '#E5E5E5',
    borderRadius: '6vw',
  },
  halfCircle: {
    width: '6vw',
    border: `0.15vh solid ${GREY_BLUE}`,
    height: '100%',
    borderTopLeftRadius: '60vw',
    borderBottomLeftRadius: '60vw',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRight: '0',
  },
  code: {
    fontSize: '4.7vw',
    lineHeight: 1.2,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1vw',
  },
  icon: {
    transform: 'rotate(90deg)',
    marginLeft: '-0.2rem',
    fontSize: '4.6vw',
  },
  iconLine: {
    width: '6vw',
    height: '0.15vh',
    backgroundColor: GREY_BLUE,
    [theme.breakpoints.down('xs')]: {
      width: '0rem',
    },
  },
  picture: {
    height: '20vh',
    transform: 'rotate(-13deg) scaleX(-1)',
    // [theme.breakpoints.down('xs')]: {
    //   width: '75%',
    // },
  },
  info: {
    fontSize: '2.5vw',
  },
  flightCode: {
    fontWeight: 600,
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  infoContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const FlightDeparture = ({
  departureCode,
  departureName,
  arrivalCode,
  arrivalName,
  flightCode,
  departureTime,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.departureRoot}>
      <div className={classes.halfCircle}></div>

      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.departureContainer}
      >
        <Grid item>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className="textCenter">
              <div className={classes.code}>{departureCode}</div>
              <div className={classes.name}>{departureName}</div>
            </Grid>
            <Grid item>
              <div className={classes.iconContainer}>
                <div className={classes.iconLine}></div>
                <FlightIcon className={classes.icon} />
              </div>
            </Grid>
            <Grid item className="textCenter">
              <div className={classes.code}>{arrivalCode}</div>
              <div className={classes.name}>{arrivalName}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" className={classes.infoContainer}>
            <Grid item className={clsx('bold', classes.info, classes.flightCode)}>
              {flightCode}
            </Grid>
            <Grid item className={clsx(classes.info)}>
              H. {departureTime}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const FlightCard = ({ el }) => {
  const classes = useStyles();

  return (
    <Card color={LIGHT_GREY}>
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <Grid item className={clsx(classes.title)}>
          <span className="bold">FLIGHT</span> DEPARTURES
        </Grid>
        <Grid item className="textCenter">
          <img src={FlightPicture} alt="flight" className={classes.picture} />
        </Grid>
        {el.departures.slice(0, 5).map((dep, y) => {
          return (
            <Grid item key={y}>
              <FlightDeparture
                departureCode={el.airport.iata}
                departureName={el.airport.name}
                arrivalCode={dep.movement.airport.iata}
                arrivalName={dep.movement.airport.name}
                flightCode={dep.number}
                departureTime={moment(dep.movement.scheduledTimeLocal, 'YYYY-MM-DD HH:mmZ').format(
                  'HH:mm',
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default FlightCard;
