import { observable, action, decorate } from 'mobx';
import { persist } from 'mobx-persist';

import { ApiService } from '../api/api.js';

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.iAmController = false;
    this.stockMarketData = {
      noContent: false,
      data: [],
    };
    this.weatherData = {
      noContent: false,
      data: [],
    };
    this.newsData = {
      noContent: false,
      data: [],
    };
    this.flightData = {
      noContent: false,
      data: [],
    };
    this.clientName = '';
    this.isLoading = false;

    this.isLoadingAll = 0;
  }

  getAll() {
    this.getStockMarketDataAll();
    this.getWeatherDataAll();
    this.getNewsDataAll();
    this.getFlightDataAll();
  }

  async getStockMarketDataAll() {
    this.isLoadingAll++;
    this.stockMarketData = await ApiService.getStockMarketData();
    this.isLoadingAll--;
  }

  async getWeatherDataAll() {
    this.isLoadingAll++;
    this.weatherData = await ApiService.getWeatherData();
    this.isLoadingAll--;
  }
  async getNewsDataAll() {
    this.isLoadingAll++;
    this.newsData = await ApiService.getNewsData();
    this.isLoadingAll--;
  }

  async getFlightDataAll() {
    this.isLoadingAll++;
    this.flightData = await ApiService.getFlightData();
    this.isLoadingAll--;
  }

  async getStockMarketData() {
    this.isLoading = true;
    this.stockMarketData = await ApiService.getStockMarketData();
    this.isLoading = false;
  }

  async getWeatherData() {
    this.isLoading = true;
    this.weatherData = await ApiService.getWeatherData();
    this.isLoading = false;
  }
  async getNewsData() {
    this.isLoading = true;
    this.newsData = await ApiService.getNewsData();
    this.isLoading = false;
  }

  async getFlightData() {
    this.isLoading = true;
    this.flightData = await ApiService.getFlightData();
    this.isLoading = false;
  }

  //   /* ACTIONS */

  setClientName(name) {
    this.clientName = name;
  }
  setLoading(isLoading) {
    this.isLoading = isLoading;
  }
}

decorate(Store, {
  iAmController: observable,
  stockMarketData: observable,
  weatherData: observable,
  newsData: observable,
  flightData: observable,
  clientName: observable,
  isLoading: observable,
  isLoadingAll: observable,
  feVendorKey: [persist, observable],

  getStockMarketData: action.bound,
  getWeatherData: action.bound,
  getNewsData: action.bound,
  getFlightData: action.bound,
  getStockMarketDataAll: action.bound,
  getWeatherDataAll: action.bound,
  getNewsDataAll: action.bound,
  getFlightDataAll: action.bound,
  setClientName: action.bound,
  setLoading: action.bound,
});

export default Store;
