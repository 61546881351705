import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-awesome-slider/dist/styles.css';
import clsx from 'clsx';
import Card from './Card';
import moment from 'moment';
import { weatherIcons } from '../constants/weatherConstants';
import { Grid, Box } from '@material-ui/core';
import { LIGHT_BLUE, DARK_BLUE, LIGHT_GREY_BLUE, GREY } from '../styles/colors.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '5%',
  },
  container: {
    width: '100%',
  },
  titlesContainer: {
    lineHeight: 1.1,
  },
  title: {
    fontSize: '3vh',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontWeight: 200,
  },
  weatherContainer: {
    color: LIGHT_GREY_BLUE,
    padding: '2vh 0',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  icon: {
    height: '30vh',
    maxWidth: '45vw',
  },
  degrees: {
    fontSize: '7vh',
    fontWeight: 200,
  },
  mainContentRoot: {
    flexGrow: 2,
    textAlign: 'center',
  },
  mainContentContainer: {
    height: '100%',
  },
  forecastElementContainer: {
    // padding: '0.2rem',
    width: 'calc(100% / 7)',
    [theme.breakpoints.down('lg')]: {
      padding: '0.1rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      maxWidth: 'calc(100% / 7)',
    },
  },
  forecastElement: {
    padding: '1.9vh 0',
    textAlign: 'center',
    // [theme.breakpoints.down('lg')]: {
    //   padding: '0.8rem 0rem',
    // },
    '&.isActive': {
      backgroundColor: LIGHT_GREY_BLUE,
      borderRadius: '4vh',
    },
  },
  forecastIcon: {
    display: 'block',
    width: '100%',
    height: '4.8vh',
    // padding: '0.3rem 0',
    '&.isActive': {
      filter: 'brightness(2)',
    },
  },
  forecastTime: {
    fontSize: '1.3vh',
  },
  forecastDegrees: {
    fontSize: '1.8vh',
    fontWeight: 200,
    '&.isActive': {
      color: '#fff',
    },
  },
}));

const WeatherForecastElement = ({ time, type, degrees, iconSrc, isActive }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.forecastElement, isActive && 'isActive')}>
      <Box color={GREY} className={classes.forecastTime}>
        {time}
      </Box>
      <img src={iconSrc} alt="sun" className={clsx(classes.forecastIcon, isActive && 'isActive')} />
      <Box className={clsx(classes.forecastDegrees, isActive && 'isActive')}>{degrees}</Box>
    </Box>
  );
};

const WeatherCard = ({ weather, i }) => {
  const classes = useStyles();

  const cityName = weather.current.data[0]['city_name'];
  const weatherCode = weather.current.data[0]['weather'].code;
  const temperature = weather.current.data[0]['temp'];

  const forecast = [weather.current.data[0]].concat(weather.forecast.data);

  return (
    <Card color={i % 2 === 0 ? DARK_BLUE : LIGHT_BLUE}>
      <Grid
        container
        direction="column"
        className={clsx(i % 2 === 0 ? 'dark' : 'light', classes.weatherContainer)}
      >
        <Grid item>
          <Grid container className={classes.titlesContainer} justify="center">
            <Grid item xs="auto" className={clsx('bold', classes.title)}>
              {cityName}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.mainContentRoot}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.mainContentContainer}
          >
            <Grid item>
              <Grid container justify="center">
                <Grid item xs="auto">
                  <img src={weatherIcons[weatherCode]} alt="sun" className={classes.icon} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center">
                <Grid item xs={6} className={classes.degrees}>
                  {temperature}°
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container wrap="nowrap">
            {forecast.map((el, i) => (
              <Grid key={i} item xs className={classes.forecastElementContainer}>
                <WeatherForecastElement
                  time={
                    i === 0 ? moment().format('HH.00') : moment(el.timestamp_local).format('HH.mm')
                  }
                  degrees={`${el.temp}°`}
                  isActive={i === 0}
                  iconSrc={weatherIcons[el.weather.code]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WeatherCard;
