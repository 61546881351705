import React, { useState, useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import { Typography, Container, Box, makeStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';

import { LIGHT_GREY, DARK_BLUE } from '../styles/colors.scss';
import { Link } from 'react-router-dom';

import { menuLinks } from '../constants/menuElements';
import Card from 'components/Card';
import { onMenuClick } from 'client/Client';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      fontSize: '4rem',
    },
    display: 'flex',
    justifyContent: 'center',
    padding: '5%',
  },
  container: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  titleContainer: {
    color: LIGHT_GREY,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3%',
  },
  title: {
    lineHeight: 1,
    fontSize: '4vh',
  },
  subtitle: {
    display: 'block',
    fontSize: '1.5vh',
    letterSpacing: '0.5rem',
  },
  elementsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  menuElement: {
    display: 'block',
    backgroundColor: LIGHT_GREY,
    padding: '4%',
    margin: '2% 0',
    textAlign: 'center',
    borderRadius: '1.5vh',
    [theme.breakpoints.down('lg')]: {
      padding: '3%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2%',
    },
  },
  menuElementLabel: {
    fontSize: '1.5vh',
    color: DARK_BLUE,
  },
}));

const MenuElement = ({ link, label, onClick }) => {
  const classes = useStyles();

  return (
    <Link to={link} className={classes.menuElement} onClick={onClick}>
      <Typography className={clsx('bold', classes.menuElementLabel)}>{label}</Typography>
    </Link>
  );
};

const Menu = observer((props) => {
  const classes = useStyles();

  return (
    <Card color={DARK_BLUE} hasShadow={true}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <div className={classes.titleContainer}>
            <div>
              <Typography variant="h2" className={clsx('bold', classes.title)}>
                Welcome
              </Typography>
              <Typography variant="h2" className={clsx('normal', classes.title)}>
                Desk
              </Typography>
              <Typography component="span" className={clsx(classes.subtitle)}>
                SOFTWARE
              </Typography>
            </div>
          </div>
        </Grid>
        {menuLinks.map((el, i) => (
          <Grid item key={i} xs={12} lg={10}>
            <MenuElement {...el} onClick={() => onMenuClick(el.link)} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
});

export default Menu;
