import bbcLogo from '../assets/images/newsSourceLogos/bbc.png';
import espnLogo from '../assets/images/newsSourceLogos/espn.png';
import foxLogo from '../assets/images/newsSourceLogos/fox.png';
import yahooLogo from '../assets/images/newsSourceLogos/yahoo.png';
import scienceLogo from '../assets/images/newsSourceLogos/science.png';
import huffingtonLogo from '../assets/images/newsSourceLogos/huffington.png';
import techcrunchLogo from '../assets/images/newsSourceLogos/techcrunch.png';

export const newsLength = 412;

const logos = {};

logos['bbc.com'] = bbcLogo;
logos['BBC News'] = bbcLogo;
logos['ESPN'] = espnLogo;
logos['Fox News'] = foxLogo;
logos['Yahoo News'] = yahooLogo;
logos['sciencemag.org'] = scienceLogo;
logos['The Huffington Post'] = huffingtonLogo;
logos['TechCrunch'] = techcrunchLogo;

export const newsLogos = logos;
