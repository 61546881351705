import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Carousel from 'components/Carousel';
import { UPDATE_INTERVAL } from '../constants/config';
import FlightCard from 'components/FlightCard';

let firstLoad = true;

const Flight = observer(() => {
  const store = useStore();

  const { flightData, isLoading } = store;

  useEffect(() => {
    if (flightData.data.length === 0 && firstLoad) {
      store.getFlightData();
    }

    if (flightData.data.length > 0) {
      firstLoad = false;
    }

    const interval = setInterval(() => {
      store.getFlightData();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <Carousel isLoading={isLoading && firstLoad} noContent={flightData.noContent}>
      {!flightData.noContent &&
        flightData.data.map((el, i) => {
          return (
            <div key={i}>
              <FlightCard el={el} />
            </div>
          );
        })}
    </Carousel>
  );
});

export default Flight;
