import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';

import Background from './components/Background';
import Header from 'components/Header';
import Footer from 'components/Footer';

import Home from './pages/Home';
import Menu from './pages/Menu';
import WelcomeClient from 'pages/WelcomeClient';
import StockMarket from 'pages/StockMarket';
import Weather from 'pages/Weather';
import Flight from 'pages/Flight';
import News from 'pages/News';

import Client from 'client/Client';
import { useStore } from 'store/storeUtils';
import All from 'pages/All';

const useStyles = makeStyles(() => ({
  container: {
    padding: '5% 5%',
    height: '100%',
    width: '100%',
  },
}));

const App = () => {
  const classes = useStyles();

  const store = useStore();

  // STOCK MARKET DATA - Il recupero è lento, recupero una prima fotografia dei dati da mostrare in caso si vada sullo stockmarket
  if (store.stockMarketData.data.length === 0) {
    store.getStockMarketData();
  }

  return (
    <>
      <Background />
      <div className={classes.container}>
        <Router basename="/">
          <Client />
          <Box height="7%">
            <Header />
          </Box>
          <Box height="85%" position="relative">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/menu" render={() => <Menu />} />
              <Route path="/welcome" component={WelcomeClient} />
              <Route path="/stock" component={StockMarket} />
              <Route path="/weather" component={Weather} />
              <Route path="/flight" component={Flight} />
              <Route path="/news" component={News} />
              <Route path="/all" component={All} />
            </Switch>
          </Box>
          <Box height="8%">
            <Footer />
          </Box>
        </Router>
      </div>
    </>
  );
};

export default App;
