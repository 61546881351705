/**
 * Get url parameter by name
 * @param {String} name value to insert
 */
const getParamByName = (name) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

export default getParamByName;
