import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme();

export const baseTheme = {
  typography: {
    fontFamily: 'Futura',
    // [defaultTheme.breakpoints.up('xl')]: {
    //   htmlFontSize: 60,
    // },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 16,
          [defaultTheme.breakpoints.up('xl')]: {
            fontSize: 60,
          },
        },
      },
    },
  },
  palette: {},
};
