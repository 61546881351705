import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: '0.1vh solid #fff',
    color: '#fff',
    height: '100%',
  },
  title: {
    fontWeight: 600,
    fontSize: '4.5vh',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} justify="center" alignItems="flex-end">
      <Grid item>
        <Typography component="span" variant="h3" className={classes.title}>
          Enjoy your visit
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
