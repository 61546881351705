import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Carousel from 'components/Carousel';
import { UPDATE_INTERVAL } from 'constants/config';
import { WEATHER_UPDATE_INTERVAL } from 'constants/weatherConstants';
import WeatherCard from 'components/WeatherCard';
import StockMarketCard from 'components/StockMarketCard';
import NewsCard from 'components/NewsCard';
import FlightCard from 'components/FlightCard';

let firstLoad = true;

const All = observer(() => {
  const store = useStore();

  const { weatherData, stockMarketData, newsData, flightData, isLoadingAll } = store;

  useEffect(() => {
    if (firstLoad) {
      store.getAll();
    }

    if (
      weatherData.data.length > 0 ||
      stockMarketData.data.length > 0 ||
      newsData.data.length > 0 ||
      flightData.data.length > 0
    ) {
      firstLoad = false;
    }

    const interval = setInterval(() => {
      store.getAll();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const cards = [];

  if (stockMarketData.data.length > 0) {
    stockMarketData.data.forEach((element) => {
      cards.push({
        ...element,
        type: 'STOCK',
      });
    });
  }
  if (weatherData.data.length > 0) {
    weatherData.data.forEach((element) => {
      cards.push({
        ...element,
        type: 'WEATHER',
      });
    });
  }
  if (newsData.data.length > 0) {
    newsData.data.forEach((element) => {
      cards.push({
        ...element,
        type: 'NEWS',
      });
    });
  }
  if (flightData.data.length > 0) {
    flightData.data.forEach((element) => {
      cards.push({
        ...element,
        type: 'FLIGHT',
      });
    });
  }

  return (
    <Carousel
      isLoading={isLoadingAll > 0}
      noContent={
        weatherData.noContent &&
        flightData.noContent &&
        newsData.noContent &&
        stockMarketData.noContent
      }
      intervalSlideChange={WEATHER_UPDATE_INTERVAL}
    >
      {cards.length > 0 &&
        cards.map((card, i) => (
          <div key={i}>
            {card.type === 'WEATHER' && <WeatherCard weather={card} i={i} />}
            {card.type === 'FLIGHT' && <FlightCard el={card} />}
            {card.type === 'STOCK' && <StockMarketCard el={card} />}
            {card.type === 'NEWS' && <NewsCard news={card} />}
          </div>
        ))}

      {/* {!weatherData.noContent &&
        weatherData.data.map((weather, i) => {
          return (
            <div key={i}>
              <WeatherCard weather={weather} i={i} />
            </div>
          );
        })} */}
      {/* {isLoadingAll === 0 &&
        !firstLoad &&
        !stockMarketData.noContent &&
        stockMarketData.data.map((el, i) => {
          return (
            <div key={i}>
              <StockMarketCard el={el} />
            </div>
          );
        })} */}
      {/* {newsWithImage.length > 0 &&
        newsWithImage.map((news, i) => {
          return (
            <div key={i}>
              <NewsCard news={news} />
            </div>
          );
        })}
      {!flightData.noContent &&
        flightData.data.map((el, i) => {
          return (
            <div key={i}>
              <FlightCard el={el} />
            </div>
          );
        })} */}
    </Carousel>
  );
});

export default All;
