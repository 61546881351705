import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// import videoMp4 from '../assets/videos/web_Redilco_bkg.mp4';
// import videoMp4_H265 from '../assets/videos/Redilco_welcome_h265_last.mp4';
// import videoOgv from '../assets/videos/web_Redilco_bkg.ogv';
// import videoWebm from '../assets/videos/web_Redilco_bkg.webm';

const useStyles = makeStyles((theme) => ({
  video: {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: -1,
  },
}));

const Background = () => {
  const classes = useStyles();

  const isTv = navigator.userAgent.includes('Web0S');
  console.log('Is a Tv? ' + isTv);

  return (
    <div
      className={classes.video}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          muted
          playsInline
          autoPlay
          loop
          class="${classes.video}"
          id="background-video"
        >
          ${
            isTv
              ? `<source src="https://www.tecmasolutions.com/clients/GVA/welcome-desk/video/Redilco_welcome_h265_last.mp4" />`
              : `<source src="${process.env.PUBLIC_URL}/video/Redilco_welcome_h265_last.mp4" type='video/mp4; codecs="hvc1"'  />`
          }
          <source src="${
            process.env.PUBLIC_URL + '/video/web_Redilco_bkg.webm'
          }" type="video/webm"  />
          <source src="${process.env.PUBLIC_URL + '/video/web_Redilco_bkg.ogv'}" type="video/ogv" />
        </video>`,
      }}
    />
    // <video muted playsInline autoPlay loop id="background-video" className={classes.video}>
    //   <source src={videoWebm} type="video/webm" />
    //   <source src={videoMp4_H265} type='video/mp4; codecs="hvc1"'></source>
    //   {/* <source src={videoMp4} type='video/mp4; codecs="avc1"' /> */}
    //   <source src={videoOgv} type="video/ogv" />
    // </video>
  );
};

export default Background;
