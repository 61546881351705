import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Slide, Fade } from 'react-awesome-reveal';
import RedilcoLogo from "../assets/images/redilco_logo.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    color: "#fff",
    fontSize: "6vh",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },
  bold: {
    fontWeight: 600,
  },
  title: {
    fontFamily: "Radikal",
  },
  logoHome: {
    minHeight: "4vh",
    maxHeight: "10vh",
    width: "60vw",
    backgroundImage: `url(${RedilcoLogo})`,
    backgroundPosition: "left",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginTop: "10px",
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Fade duration="2000">
        <Slide direction="bottom" duration="2000">
          <div>Welcome to</div>
          <div className={classes.title}>
            {/* <span className={classes.bold}>GVA</span> Redilco &amp; Sigest */}
            <div className={classes.logoHome}/>
          </div>
        </Slide>
      </Fade>
    </div>
  );
};

export default Home;
