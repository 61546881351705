import rainThunder from '../assets/images/weatherIcon/pioggia_temporali0.svg';
import rain from '../assets/images/weatherIcon/pioggia0.svg';
import heavyRain from '../assets/images/weatherIcon/piaggia intensa0.svg';
import snow from '../assets/images/weatherIcon/neve0.svg';
import sunCloud from '../assets/images/weatherIcon/sole-nuvola0.svg';
import sun from '../assets/images/weatherIcon/sole0.svg';
import twoClouds from '../assets/images/weatherIcon/nuvole0.svg';
import cloud from '../assets/images/weatherIcon/nuvola0.svg';

const icons = {};

icons['200'] = rainThunder;
icons['201'] = rainThunder;
icons['202'] = rainThunder;
icons['230'] = rainThunder;
icons['231'] = rainThunder;
icons['232'] = rainThunder;
icons['233'] = rainThunder;

icons['300'] = rain;
icons['302'] = rain;
icons['500'] = rain;
icons['501'] = rain;
icons['511'] = rain;

icons['502'] = heavyRain;
icons['520'] = heavyRain;
icons['521'] = heavyRain;
icons['522'] = heavyRain;

icons['601'] = snow;
icons['602'] = snow;
icons['610'] = snow;
icons['611'] = snow;
icons['612'] = snow;
icons['621'] = snow;
icons['622'] = snow;
icons['623'] = snow;

icons['700'] = sunCloud;
icons['711'] = sunCloud;
icons['721'] = sunCloud;
icons['731'] = sunCloud;
icons['741'] = sunCloud;
icons['751'] = sunCloud;

icons['800'] = sun;
icons['801'] = sun;

icons['802'] = twoClouds;
icons['803'] = twoClouds;

icons['804'] = cloud;

icons['900'] = rain;

export const weatherIcons = icons;

export const WEATHER_UPDATE_INTERVAL = 7000;
