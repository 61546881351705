import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Hidden } from '@material-ui/core';

import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/dist/styles.css';

import AnimationStyles from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss';
import test from '../styles/carousel.scss';

import clsx from 'clsx';

import loadingGif from '../assets/images/loading.svg';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '25%',
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Box height="100%" width="100%" display="flex" alignContent="center" justifyContent="center">
      <img src={require('assets/images/loading.svg')} alt="loading" className={classes.image} />
    </Box>
  );
};

export default Loading;
