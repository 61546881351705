import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import Store from 'store/store.js';
import { StoreProvider } from 'store/storeUtils.js';
import { create } from 'mobx-persist';
import 'styles/main.scss';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { baseTheme } from 'styles/baseTheme';
import { CssBaseline } from '@material-ui/core';

const theme = createMuiTheme(baseTheme);

const hydrate = create({});

const store = new Store();

hydrate('store', store).then(() => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StoreProvider value={store}>
        <App />
      </StoreProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
});

serviceWorker.register();
