import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import 'react-awesome-slider/dist/styles.css';

import clsx from 'clsx';
import Card from './Card';
import moment from 'moment';

import { VIOLET, BLUE } from '../styles/colors.scss';

import { Grid, Box, useMediaQuery } from '@material-ui/core';
import {
  ResponsiveContainer,
  ComposedChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Area,
  Line,
} from 'recharts';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  titlesContainer: {
    lineHeight: 1.1,
    marginBottom: '2vh',
    paddingLeft: '5%',
  },
  title: {
    fontSize: '3.5vh',
  },
  subtitle: {
    fontWeight: 200,
    fontSize: '2.5vh',
  },
  stockContainer: {
    color: '#fff',
    height: '100%',
  },
  chartContainer: {
    height: '30%',
    marginBottom: '15%',
    marginTop: '5%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5%',
    },
  },
  stockListContainer: {
    padding: '0 5%',
  },
  stockInfo: {
    backgroundColor: '#28293E',
    borderRadius: '4vh',
    padding: '0.7vh',
    fontWeight: 600,
    marginBottom: '1.4vh',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  stockName: {
    background: 'linear-gradient(90deg, rgba(35,33,54,1) 55%, rgba(37,52,90,1) 85%)',
    height: '5.4vh',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4vh',
    fontSize: '2vh',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '0 3vw',
  },
  stockValue: {
    padding: '2vw',
    fontSize: '1.8vh',
    display: 'flex',
    alignItems: 'center',
  },
  stockDelta: {
    background: VIOLET,
    borderRadius: '100%',
    height: '5.4vh',
    width: '5.4vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5vh',
    whiteSpace: 'nowrap',
  },
  stockNameText: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden',
  },
  stockNameContainer: {
    maxWidth: '50% !important',
  },
}));

const StockInfo = ({ name, value, delta }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.stockInfo} justify="space-between" wrap="nowrap">
      <Grid item xs={4} md={'auto'} className={classes.stockNameContainer}>
        <div className={classes.stockName}>
          <span className={classes.stockNameText}>{name}</span>
        </div>
      </Grid>
      <Grid item xs={'auto'}>
        <Box display="flex">
          <div className={classes.stockValue}>{value}</div>
          <div className={classes.stockDelta}>{delta}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

const AxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#fff" fontSize="0.8vh">
        {payload.value}
      </text>
    </g>
  );
};

const StockMarketCard = ({ el }) => {
  const classes = useStyles();

  const exchangeName = el.name;
  const subtitle1 = el.chart.meta.symbol;
  const subtitle2 = '';

  const xTime = el.chart.timestamp;
  const yValue = el.chart.indicators.quote[0].close;

  const dataToShow = [];
  for (let i = 0; i < xTime.length; i++) {
    dataToShow.push({
      name: `H. ${moment.unix(xTime[i]).format('HH:mm')}`,
      value: yValue[i],
    });
  }

  const stocks = el.info.slice(0, 3);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card>
      <Grid container direction="column" className={classes.stockContainer} wrap="nowrap">
        <Grid item className={classes.titlesContainer}>
          <div className={clsx('bold', classes.title)}>{exchangeName}</div>
          <div className={clsx(classes.subtitle)}>{subtitle1}</div>
          <div className={clsx(classes.subtitle)}>{subtitle2}</div>
        </Grid>
        <Grid item className={clsx(classes.chartContainer)}>
          <ResponsiveContainer>
            <ComposedChart data={dataToShow}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={BLUE} stopOpacity={0.8} />
                  <stop offset="75%" stopColor={BLUE} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" tick={<AxisTick />} padding={{ left: isMobile ? 20 : 200 }} />
              <YAxis
                axisLine={false}
                tick={<AxisTick />}
                domain={['auto', 'auto']}
                width={isMobile ? 40 : 100}
              />
              <CartesianGrid vertical={false} stroke="rgba(0,0,0,0.5)" strokeWidth="0.05rem" />
              <Area
                type="monotone"
                dataKey="value"
                stroke={BLUE}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke={BLUE}
                strokeWidth="0.2rem"
                dot={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item className={clsx(classes.stockListContainer)}>
          {stocks.map((stock, i) => (
            <StockInfo
              key={i}
              name={stock.shortName ? stock.shortName : stock.symbol}
              delta={stock.regularMarketChangePercent.fmt}
              value={stock.regularMarketPrice.fmt}
            />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default StockMarketCard;
