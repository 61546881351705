export const menuLinks = [
  {
    link: '/',
    label: 'WELCOME TO REDILCO & SIGEST',
  },
  {
    link: '/welcome',
    label: 'WELCOME CUSTOMER',
  },
  {
    link: '/stock',
    label: 'STOCK MARKET',
  },
  {
    link: '/weather',
    label: 'WEATHER REPORT',
  },
  {
    link: '/news',
    label: 'NEWS',
  },
  {
    link: '/flight',
    label: 'FLIGHT DEPARTURES',
  },
  {
    link: '/all',
    label: 'ALL',
  },
];
