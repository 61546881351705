import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Carousel from 'components/Carousel';
import { UPDATE_INTERVAL } from 'constants/config';
import { WEATHER_UPDATE_INTERVAL } from 'constants/weatherConstants';
import WeatherCard from 'components/WeatherCard';

let firstLoad = true;

const Weather = observer(() => {
  const store = useStore();

  const { weatherData, isLoading } = store;

  useEffect(() => {
    if (weatherData.data.length === 0 && firstLoad) {
      store.getWeatherData();
    }

    if (weatherData.data.length > 0) {
      firstLoad = false;
    }

    const interval = setInterval(() => {
      store.getWeatherData();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <Carousel
      isLoading={isLoading && firstLoad}
      noContent={weatherData.noContent}
      intervalSlideChange={WEATHER_UPDATE_INTERVAL}
    >
      {!weatherData.noContent &&
        weatherData.data.map((weather, i) => {
          return (
            <div key={i}>
              <WeatherCard weather={weather} i={i} />
            </div>
          );
        })}
    </Carousel>
  );
});

export default Weather;
