export const weatherParams = [
  {
    city: 'Milan',
    lat: 45.464211,
    long: 9.191383,
  },
  { city: 'Rome', lat: 41.902782, long: 12.496366 },
];

export const forecastHours = 6;

export const markets = [
  { name: 'FTSEMIB Milan', symbol: 'FTSEMIB.MI', region: 'IT' },
  { name: 'NASDAQ New York', symbol: '^IXIC', region: 'US' },
  { name: 'S&P500 New York', symbol: '^GSPC', region: 'US' },
  { name: 'DOW JONES New York', symbol: '^DJI', region: 'US' },
  { name: 'NIKKEY Tokyo', symbol: '^N225', region: 'GB' },
  { name: 'FTSE 100 London', symbol: '^FTSE', region: 'GB' },
  { name: 'CAC40 Paris', symbol: '^FCHI', region: 'FR' },
];

export const departureAirports = [
  { icao: 'LIMC', iata: 'MXP', name: 'Milano/Malpensa' },
  { icao: 'LIML', iata: 'LIN', name: 'Milano/Linate' },
  { icao: 'LIRF', iata: 'FCO', name: 'Roma/Fiumicino' },
  { icao: 'LIRA', iata: 'CIA', name: 'Roma/Ciampino' },
];

export const arrivalAirports = [
  'LIMC',
  'LIML',
  'LIRF',
  'LIRA',
  'EGLL',
  'EGKK',
  'EGSS',
  'KJFK',
  'OMDB',
  'OMAA',
  'LFPG',
  'LFPB',
  'UUDD',
  'UUEE',
  'RJTT',
  'VHHH',
];

export const newsTopics = [
  { topic: 'world', sourceToUse: 'bbc.com', otherSources: [] },
  { topic: 'business', sourceToUse: 'bbc.com', otherSources: [] },
  { topic: 'sports', sourceToUse: 'ESPN', otherSources: [] },
  { topic: 'entertainment', sourceToUse: 'Fox News', otherSources: ['Yahoo News'] },
  {
    topic: 'science',
    sourceToUse: 'bbc.com',
    otherSources: ['sciencemag.org', 'The Huffington Post'],
  },
  { topic: 'technology', sourceToUse: 'bbc.com', otherSources: ['TechCrunch', 'BBC News'] },
];
