import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { VIOLET } from '../styles/colors.scss';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '3rem',
    width: '3rem',
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      color={VIOLET}
      textAlign="center"
    >
      <div>
        <Box>
          <HelpOutlineIcon className={classes.icon} />
        </Box>
        <Box fontSize="1.5rem">No content available</Box>
      </div>
    </Box>
  );
};

export default Loading;
