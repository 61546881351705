import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles, FormControl, Input, ButtonBase } from '@material-ui/core';
import { Fade, Slide } from 'react-awesome-reveal';
import CheckIcon from '@material-ui/icons/Check';

import { useStore } from 'store/storeUtils.js';
import { changeClientName } from 'client/Client';
import getParamByName from 'utils/getParamByName';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    color: '#fff',
    fontSize: '8vh',
    lineHeight: 1,
    fontWeight: 600,
    overflow: 'hidden',
    // [theme.breakpoints.down('lg')]: {
    //   fontSize: '4rem',
    // },
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  inputContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
  },
  inputRoot: {
    width: '85%',
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    fontWeight: 600,
    textAlign: 'center',
    padding: '1rem',
    color: '#fff',
    fontSize: '1.5rem',
  },
  button: {
    width: '15%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const isController = getParamByName('isController') === 'true';

const WelcomeClient = observer(() => {
  const store = useStore();
  const { clientName } = store;

  const [tempName, setTempName] = useState('');

  const classes = useStyles();

  const updateName = () => {
    changeClientName(tempName);
    if (isController) {
      store.setClientName(tempName);
    }
  };

  return (
    <div className={classes.container}>
      <Fade duration="2000">
        <Slide direction="bottom" duration="2000">
          <div>Welcome</div>
          <div>{clientName}</div>
        </Slide>
      </Fade>
      {isController && (
        <div className={classes.inputContainer}>
          <FormControl variant="outlined" classes={{ root: classes.inputRoot }}>
            <Input
              id="clientName"
              aria-describedby="my-helper-text"
              disableUnderline
              placeholder="Client Name"
              inputProps={{ className: classes.input }}
              value={tempName}
              onChange={(event) => setTempName(event.target.value)}
            />
          </FormControl>
          <ButtonBase className={classes.button} onClick={updateName}>
            <CheckIcon />
          </ButtonBase>
        </div>
      )}
    </div>
  );
});

export default WelcomeClient;
