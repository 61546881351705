import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Carousel from 'components/Carousel';
import { UPDATE_INTERVAL } from 'constants/config';
import StockMarketCard from 'components/StockMarketCard';

let firstLoad = true;

const StockMarket = observer(() => {
  const store = useStore();
  const { isLoading, stockMarketData } = store;

  useEffect(() => {
    if (stockMarketData.data.length === 0 && firstLoad) {
      store.getStockMarketData();
    }

    if (stockMarketData.data.length > 0) {
      firstLoad = false;
    }

    const interval = setInterval(() => {
      store.getStockMarketData();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <Carousel isLoading={isLoading && firstLoad} noContent={stockMarketData.noContent}>
      {!stockMarketData.noContent &&
        stockMarketData.data.map((el, i) => {
          return (
            <div key={i}>
              <StockMarketCard el={el} />
            </div>
          );
        })}
    </Carousel>
  );
});

export default StockMarket;
