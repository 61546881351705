import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-awesome-slider/dist/styles.css';
import clsx from 'clsx';
import Card from './Card';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { LIGHT_GREY_2, GREY } from '../styles/colors.scss';
import { newsLogos } from 'constants/newsConstants';

import TextTruncate from 'react-text-truncate';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: '1vh',
  },
  image: {
    width: '100%',
    minHeight: '45%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: '0.5vh',
  },
  topic: {
    fontSize: '1.3vh',
    textTransform: 'uppercase',
    padding: '.7rem 0',
  },
  newsContainer: {
    padding: '0 3.5vw',
  },
  newsTitle: {
    fontSize: '3vh',
    lineHeight: '1.1',
    marginBottom: '.7rem',
  },
  newsContent: {
    fontSize: '1.4vh',
    marginBottom: '.7rem',
  },
  date: {
    color: GREY,
    fontSize: '1.3vh',
  },
  logo: {
    height: '3vh',
  },
}));

const NewsCard = ({ news }) => {
  const classes = useStyles();

  const topic = news.category;
  const title = news.title;
  const summary = news.content;
  const date = news.date;
  const img = news.img;
  const publisher = news.source_name;

  return (
    <Card color={LIGHT_GREY_2}>
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <Grid item>
          <Grid container className={classes.titlesContainer} justify="center">
            <Grid item xs="auto" className={clsx('bold', classes.title)}>
              <img src={newsLogos[publisher]} alt="log" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
        {img && (
          <Grid item className={classes.image} style={{ backgroundImage: `url(${img})` }}></Grid>
        )}

        <Grid item className={classes.newsContainer}>
          <Grid container direction="column">
            <Grid item className={clsx('bold', classes.topic)}>
              {topic}
            </Grid>
            <Grid item className={clsx('bold', classes.newsTitle)}>
              {title}
            </Grid>
            <Grid item id="news-text" className={clsx(classes.newsContent)}>
              <TextTruncate
                line={4}
                element="p"
                truncateText="…"
                text={summary}
                // textTruncateChild={<a href="#">Read on</a>}
              />
            </Grid>
            <Grid item className={clsx('bold', classes.date)}>
              {moment(date).fromNow()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NewsCard;
